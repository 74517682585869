.banner {
    min-height: 100vh;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    @include sm{
        min-height:30vh;
    }
    &__about {
        position: absolute;
        left: 10%;
        top: 50%;
        z-index: 17;
        -webkit-text-stroke: 5px $white;
        @include md{
            top:10%;
            -webkit-text-stroke-width: 3px;
        }
    }
    &__image {
        position: absolute;
        bottom: -30%;
        max-width: 900px;
        max-height:800px;
        z-index: 16;
        @include md{
            max-width:700px;
        }
        @include sm{
           display:none;
        }
    }


}
