.latestprojects {
    h2 {
        text-align: center;
        @include section-margin;
    }
    .latestprojectgrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        &__singleproject {
            min-height: 50vh;
            overflow: hidden;
            @include center-content;
            color: $white;
            position: relative;
            strong {
                top: -200px;
                position: relative;
                @include transition;
                text-transform: lowercase;
            }
            h4 {
                font-size: $fontDefault * 5;
                position: relative;
                z-index: 1;
                -webkit-text-stroke: 1px $white;
                color: transparent;
                @include md {
                    font-size: $fontDefault * 3;
                }
                @include sm {
                    font-size: $fontDefault * 2;
                }
            }
            &:hover {
                text-decoration: none;
                strong {
                    top: 0;
                }
                h4 {
                    color: $white;
                    -webkit-text-stroke: 0;
                }
            }
            @include md {
                min-height: 25vh;
                margin-top: 20px;
            }
            &:before {
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($salmon, 0.4);
                position: absolute;
                z-index: 0;
                @include transition;
            }
            &:hover:before {
                height: 0;
            }
        }
        &__titles {
            padding: 0 20px;
            text-align: center;
            position: relative;
            z-index: 1;
        }
        &--first {
            grid-column: 2 / span 1;
        }
        @include md {
            grid-template-columns: 1fr;
        }
    }
}
