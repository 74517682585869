$Montserrat: 'Montserrat';

$black: #707070;
$white: #fff;
$salmon: #EF9F7F;
$teal: #87C3BA;
$red: #ff462d;

$fontXLarge: 10rem;
$fontMedium: 3rem;
$fontDefault: 1rem;

$fontWeightBold: 600;
$fontWeightMed: 500;
$fontWeightReg: 400;
$fontWeightLight: 300;

$contentZindex: 4;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1600px;