.grid {
    &__line {
        position: fixed;
        background: rgba($white, 0.4);
        z-index: 10;
        &--horizontal {
            height: 1px;
            
            &:first-child {
                top: 20%;
                @include animation('gridWidth 2s 1 1s forwards');
            }
            &:nth-child(2) {
                top: 50%;
                @include animation('gridWidth 2s 1 1.5s forwards');
            }
            &:nth-child(3) {
                top: 80%;
                @include animation('gridWidth 2s 1 2s forwards');
            }

        }
        &--vertical {
            width: 1px;
            &:nth-child(4) {
                left: 10%;
                @include animation('gridHeight 2s 1 1s forwards');
            }
            &:nth-child(5) {
                left: 30%;
                @include animation('gridHeight 2s 1 1.25s forwards');
            }
            &:nth-child(6) {
                left: 50%;
                @include animation('gridHeight 2s 1 1.5s forwards');
            }
            &:nth-child(7) {
                left: 70%;
                @include animation('gridHeight 2s 1 2s forwards');
            }
            &:nth-child(8) {
                left: 90%;
                @include animation('gridHeight 2s 1 2.25s forwards');
            }
        }
    }
}
@include keyframes(gridWidth) {
    0% { width: 0; }
    100% { width: 100%; }
  }
  @include keyframes(gridHeight) {
    0% { height: 0; }
    100% { height: 100%; }
  }
.btn {
    &__menu {
        position: fixed;
        z-index: 25;
        top: 20px;
        right: 20px;
        cursor: pointer;
        height: 50px;@include transition;
        span {
            background: $salmon;
            width: 50px;
            height: 5px;
            margin-bottom: 10px;
            display: block;@include transition;
        }
        &.btn--active {
            top: 20px;
            right: 20px;
            cursor: pointer;
            padding-top: 20px;
            span {
                background: $white;
                width: 50px;
                height: 5px;
                margin-bottom: 10px;
                display: block;
                &:first-child {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                    position: relative;
                    top: -14px;
                }
            }
            
        &:hover span{background:$black}
        }
    }
}

.main {
    z-index: 15;
    position: relative;
    background: $white;
}

.preloader {
    @include center-content;
    height: 100vh;
    &__home{
        height:100vh;@include center-content;
    }
    &__branding {
        max-width: 100px;
        height: 100px;
        display: block;
        .svg--a,
        .svg--y,
        .svg--title {
            fill: $teal;
        }
    }
    &__svg {
        max-width:70px;margin:0 auto;display:block;
    }
    &--c1{
      fill:$teal;
    }
    &--c2{
      fill:$teal;
    }
    &--c3{
      fill:$teal;
    }
}

img{max-width:100%}

.center{@include center-content}

.hide {
    display: none !important;
}



.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}