.projects {
    .nav--main {
        display: none;
    }
    .footer {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 3;
        width: 100%;
    }
}

.linkgrid {
    padding-top: 20vh;
    padding-left: 10vw;
    padding-right: 10vw;
    min-height: 100vh;
    background-color: rgba($black,0.2);
    @include md{
        background-color: rgba($black,0.4);
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 30vh;
        @include position-content;
        &:hover a {
            color: $white;
            opacity: 0.5;
        }
        @include md {
            grid-template-columns: 1fr 1fr;
        }
        @include sm {
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
        }
    }
    &__linkwrap {
        text-align: center;
        @include center-content; 
        a {
            cursor: pointer;
            font-size: $fontDefault * 2;
            color: $white;
            padding: 10px;
            @include transition;
            &:hover {
                color: $white;
                text-decoration: none;
                opacity: 1;
                margin-bottom: 10px;
            }
            h2,
            h3 {
                font-weight: $fontWeightReg;
            }
            h2 {
                font-size: $fontDefault * 2;
            }
            h3 {
                font-size: $fontDefault * 1.5;
            }
        }

    }
    &__background {
        position: fixed;
        background-size: cover;
        background-position: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: #000;
        opacity: 0;
        @include transition;
    }
    &__fadeIn {
        opacity: 1;
    }
}

.project {
    margin-top: 50px;
    .projectcontent {
        @include section-margin;
        h2 {
            color: $salmon;
            margin-bottom: 0;
            font-size: $fontDefault * 8;
        }
        h3 {
            font-size: $fontDefault * 4;
            color: $teal;
            text-align: right;
        }
        h4 {
            font-size: $fontDefault * 2;
            color: $black;
            text-align: right;
            margin-bottom: 20px;
        }
        sub {
            display: block;
            color: $teal;
        }
        p {
            margin-bottom: 20px;
        }
    }
}

.projectgallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    @include section-margin;
    &__img {
        display: flex;
    }
    img {
        margin-bottom: 20px;
        max-width: 100%;
    }
    img:nth-child(odd) {
        justify-self: end;
        grid-column: 1 / span 2;
    }
    img:nth-child(even) {
        justify-self: start;
        grid-column: 3 / span 2;
    }
    img:nth-child(5) {
        grid-column: 1/ 1;
        justify-self: end;
    }
    img:nth-child(6) {
        grid-column: 2/ 2;
        justify-self: end;
    }
    img:nth-child(7) {
        grid-column: 3/ 3;
        justify-self: start;
    }
    img:nth-child(8) {
        grid-column: 4/ 4;
        justify-self: start;
    }
}

.img-responsive {
    max-width: 100%;
}
