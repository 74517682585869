.slider {
  position:relative;min-height:100vh;
  &__element{
    overflow:hidden;display:flex;justify-content:center;align-items:center;height:100%;width:100%;position:relative;
    &:before{content:'';top:0;left:0;width:100%;height:100%;background:$black;opacity:0.3;position:absolute;z-index:0}
    h2 {
      font-size: $fontXLarge;font-weight: $fontWeightBold;-webkit-text-stroke: 3px $white;color: rgba($white, 0);z-index:1;
      @include xl {
        font-size:$fontXLarge - 2;
      }
      @include lg {
        font-size:$fontXLarge - 4;
      }
      @include md {
        font-size:$fontXLarge - 6;
      }
      @include sm {
        font-size:$fontXLarge - 7;bottom:-5%;
        -webkit-text-stroke: 1px $white
      }
    }
    &--number{
      position:absolute;left:-5%;bottom:-10%;font-size:$fontXLarge * 5;line-height:1;color:rgba($white, 0);font-weight:$fontWeightBold; -webkit-text-stroke: 5px $white;z-index:2;
      @include xl {
        font-size:$fontXLarge * 3;
      }  
      @include md {
        font-size:$fontXLarge * 2.5;bottom:-5%;
      }
      @include sm {
        font-size:$fontXLarge * 1;bottom:10px;left:10px;
      }
    }
  }
  &__title{
    z-index:50;position:absolute;cursor:pointer;
    &:hover{text-decoration:none;}
    h2{@include transition;}
    &:hover h2{-webkit-text-stroke-color:$salmon;}
  }
  &__link{
    color:$salmon;transform:translateY(100px);font-size:$fontMedium;letter-spacing:1px;@include transition;
    &:hover{
      color:$white;text-decoration:none;
    }
  }
  &__mainlink{
    position:absolute;z-index:5;left:0;right:0;bottom:5%;margin:0 auto;display:block;text-align:center;font-size:$fontMedium;color:$salmon;width:fit-content;
    &:hover {
      color: $white;
      text-decoration: none;
      &:after{
        bottom:5px;
        opacity:1;
        
      }
  }
  &:after {
    content:"";
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      bottom: -5px;
      opacity:0;
      left: 0;
      background:$salmon;transition:all 0.25s ease-in-out;
  }
    @include sm {
      left:auto;right:10px;
    }
  }
}

.awssld__next {
  right: 100px;
}
.awssld__prev {
  left: 100px;
}

.awssld__wrapper{height:100vh;
  .preloader__branding{
    margin:200px auto 0;
  }
}
.awssld__content{background-size:cover;background-position:center center;}