@mixin position-content {
    position: relative;
    z-index: 5;
}

@mixin vertical-text {
    transform: rotate(-90deg);
}

@mixin center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin section-margin {
    margin-bottom: 150px;
    @include md {
        margin-bottom: 100px;
    }
    @include sm {
        margin-bottom: 50px;
    }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

// custom
@mixin rwd($screen) {
    @media (max-width: $screen+"px") {
        @content;
    }
}


//animations
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@mixin link{
    &:hover {
        color: $white;
        text-decoration: none;
        &:after{
          bottom:-5px;
          opacity:1;
          
        }
    }
    &:after {
      content:"";
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        bottom: 0px;
        opacity:0;
        left: 0;
        background:$salmon;transition:all 0.25s ease-in-out;
    }
}

@mixin transition{
    transition:all 0.25s ease-in-out;
}