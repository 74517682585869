.header {
    width: 100%;
    height: 0;
    position: absolute;
    z-index: 4;
}

.branding {
    &__link {
        max-width: 8%;
        display: block;
        z-index: 5;
        position: absolute;
    }
    &__svg {
        min-width: 200px;
        .svg--a {
            fill: $salmon;
        }
        .svg--y {
            fill: $teal;
        }
        .svg--title {
            fill: $white;
        }
        @include lg {
            min-width: 120px;
        }
        @include sm {
            min-width: 100px;
            margin-left: 10px;
            margin-top: 10px;
        }
    }
    &--white {
        .svg--a,
        .svg--title,
        .svg--y {
            fill: $white;
            @include transition;
        }
        &:hover {
            .svg--a,
            .svg--title,
            .svg--y {
                fill: $black;
            }
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: $salmon;
    overflow-y: auto;
    @include transition;
    &.show {
        left: 0;
    }
    &__branding {
        display: inline-block;
        vertical-align: top;
        max-width: 8%;
        img {
            display: block;
            max-width: 100%;
        }
    }

    &__social {
        position: absolute;
        bottom: 0;
        right: 20px;
        z-index: 10q;
        li {
            display: inline;
        }
        li a {
            display: inline-block;
            margin-left: 20px;
            font-size: 0;
            width: 35px;
            height: 50px;
            vertical-align: top;
            background-repeat: no-repeat;
        }
        li:first-child a {
            fill: $white;
            @include transition;
            &:hover {
                fill: $black;
            }
        }
        li:nth-child(2) a {
            @include transition;
            fill: $white;
            &:hover {
                fill: $black;
            }
        }
    }
}
