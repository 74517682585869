.pagenotfound {
    text-align: center;
    min-height: 100vh;
    background-color: rgba($black, 0.3);
    &__content {
        padding: 0 50px;
        @include sm {
            padding: 0 15px;
        }
    }
    &__link {
        display: block;
        position: relative;
        color:$salmon;
        margin:0 auto;
        width:fit-content;
        &:hover {
            color: $white;
            text-decoration: none;
            &:after {
                bottom: -5px;
                opacity: 1;
            }
        }
        &:after {
            content: "";
            width: 100%;
            height: 2px;
            display: block;
            position: absolute;
            bottom: 0px;
            opacity: 0;
            left: 0;
            background: $salmon;
            transition: all 0.25s ease-in-out;
        }
    }
    h3 {
        font-size: $fontMedium;
    }
    .branding__svg {
        display: block;
    }
}
